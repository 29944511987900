<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      &copy; حق کپی رایت توسط
      <b-link
        class="ml-25"
        href="https://derakht.co/"
        target="_blank"
      > بانک دیجیتال درخت </b-link>
      <span class="d-none d-sm-inline-block">محفوظ است</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      <feather-icon
        icon="AlertCircleIcon"
        size="21"
        class="text-danger stroke-current"
      />
      &nbsp;طراحی شده توسط
      <b-link
        class="ml-25"
        href="https://derakht.co/"
        target="_blank"
      > بانک دیجیتال درخت  </b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
